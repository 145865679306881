import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024 1024">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="">


<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M708.957886,541.247925 
	C689.421631,554.423401 661.319946,551.787170 646.348816,535.593323 
	C630.720947,518.688965 629.467468,491.625305 643.473145,473.507660 
	C658.733704,453.766663 692.361328,450.270996 711.218323,466.463501 
	C711.711914,466.887360 712.223816,467.289917 712.831604,467.787933 
	C713.118042,467.431458 713.463074,467.202515 713.484680,466.946228 
	C714.269043,457.665710 714.264893,457.665314 723.998535,457.666351 
	C735.330505,457.667572 735.352600,457.667603 735.353699,469.003876 
	C735.356201,494.650208 735.587341,520.300171 735.219971,545.941406 
	C735.026550,559.443481 730.458130,571.460510 719.519653,580.204834 
	C711.547363,586.578003 702.024963,588.925537 692.093811,589.725708 
	C675.319092,591.077393 659.210632,588.718811 644.359436,580.307617 
	C637.867615,576.630920 637.869263,576.319946 641.559814,570.071777 
	C642.830078,567.921143 644.349060,565.882446 645.347046,563.613464 
	C647.268127,559.245850 649.205017,558.746277 653.539734,561.503723 
	C665.662048,569.215149 679.097717,571.450928 693.271301,568.856506 
	C706.103821,566.507568 713.496094,556.732483 712.206604,539.527588 
	C710.847290,539.321716 710.239807,540.583984 708.957886,541.247925 
M673.926453,527.733032 
	C676.512329,528.328064 679.070496,529.128418 681.688965,529.482727 
	C693.149109,531.033569 704.328247,525.652832 709.736816,516.126099 
	C714.602966,507.554688 713.741455,494.452271 707.808289,486.797913 
	C700.944641,477.943085 688.092163,474.202240 676.143677,477.581635 
	C665.675781,480.542297 658.272461,489.057068 657.351562,499.195190 
	C656.138977,512.544861 661.165710,521.527588 673.926453,527.733032 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M215.282745,548.812744 
	C215.558624,552.590210 214.246246,554.275940 210.731613,554.192688 
	C205.735016,554.074341 200.730728,554.058594 195.735138,554.195923 
	C192.193787,554.293213 191.041611,552.528015 191.070770,549.297302 
	C191.126419,543.131836 191.061661,536.965393 191.062759,530.799316 
	C191.068436,498.968964 191.070618,467.138611 191.094269,435.308258 
	C191.099899,427.720581 191.135559,427.718811 198.479599,427.707642 
	C201.812622,427.702545 205.145691,427.695282 208.478653,427.708221 
	C215.192032,427.734283 215.249283,427.772858 215.262314,434.309906 
	C215.288559,447.475281 215.461548,460.644165 215.204178,473.804352 
	C215.111420,478.547577 216.629456,479.294647 221.253113,479.396454 
	C238.079117,479.766785 254.896362,479.084930 271.708679,479.454254 
	C276.122833,479.551239 277.906036,478.722900 277.782410,473.807831 
	C277.442993,460.317657 277.822266,446.810577 277.582245,433.316132 
	C277.503021,428.863861 279.044708,427.324402 283.369263,427.658905 
	C287.013611,427.940735 290.697418,427.701630 294.363708,427.712189 
	C301.347290,427.732269 301.352661,427.737885 301.352783,434.520996 
	C301.353485,470.850922 301.351685,507.180817 301.349030,543.510742 
	C301.348877,545.677185 301.230225,547.849365 301.350830,550.008606 
	C301.521210,553.059814 300.041168,554.200317 297.162354,554.167847 
	C292.663544,554.117065 288.152985,553.981506 283.667053,554.232422 
	C279.212158,554.481628 277.490173,552.954102 277.579590,548.247009 
	C277.845398,534.253418 277.521332,520.248901 277.757385,506.254242 
	C277.832336,501.810577 276.724823,500.107788 271.937988,500.183685 
	C254.943863,500.453217 237.941315,500.412140 220.945496,500.201904 
	C216.622971,500.148468 215.108398,501.375305 215.187149,505.840637 
	C215.436890,520.001526 215.284363,534.169556 215.282745,548.812744 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M763.011169,461.859497 
	C788.863892,449.357910 817.762146,458.164673 829.905945,482.021851 
	C832.736572,487.582886 833.794128,493.590332 834.512390,499.764008 
	C835.027527,504.191498 833.193359,505.574249 829.265137,506.328918 
	C809.186951,510.186096 789.145081,514.232239 769.094238,518.231140 
	C767.481201,518.552795 765.896606,519.016785 763.597595,519.589172 
	C766.882324,527.161316 772.328796,531.718689 779.682251,533.564880 
	C791.860352,536.622253 803.530090,535.684509 813.497620,526.980164 
	C815.668884,525.084045 817.225098,525.364624 818.905640,527.467224 
	C821.295471,530.457397 823.671265,533.469666 826.241577,536.301453 
	C828.299255,538.568481 827.767151,540.196411 825.824036,542.232239 
	C818.551636,549.851746 809.359924,553.423279 799.238342,554.702515 
	C784.077271,556.618652 769.585083,554.641357 756.995605,545.211975 
	C742.386353,534.269775 737.071533,519.186890 738.313232,501.386688 
	C739.527100,483.983795 747.494507,470.775604 763.011169,461.859497 
M773.105835,501.509430 
	C785.846375,498.936951 798.586914,496.364471 811.208557,493.815979 
	C811.153015,490.453247 809.995239,488.453308 808.691040,486.523468 
	C802.338257,477.122955 790.532349,473.160736 778.677307,476.434845 
	C768.822449,479.156616 760.894775,489.552948 760.412781,500.275208 
	C760.292236,502.958160 761.283997,503.957184 763.971985,503.321960 
	C766.715027,502.673767 769.497009,502.190613 773.105835,501.509430 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M333.041992,460.856903 
	C350.965515,453.630371 367.963745,454.472107 383.746887,465.322174 
	C395.779816,473.594208 401.392303,485.646423 402.425781,500.097595 
	C402.720459,504.218140 401.058563,505.594727 397.317139,506.312866 
	C377.235901,510.167419 357.191071,514.212036 337.138153,518.213501 
	C335.387390,518.562805 333.505676,518.612915 331.752075,520.215271 
	C335.958740,529.163147 343.352814,533.544617 352.796906,534.683960 
	C363.421234,535.965759 373.430542,534.375916 381.693756,526.800415 
	C383.669556,524.989075 385.155090,525.403625 386.681427,527.335571 
	C388.228302,529.293518 389.889282,531.161621 391.507416,533.062988 
	C397.149017,539.691895 397.258423,539.906189 390.284607,545.425903 
	C379.487640,553.971619 366.797974,555.805786 353.507538,555.227356 
	C330.791718,554.238647 312.565948,539.948730 307.494324,518.415039 
	C302.101349,495.517120 310.947601,471.446838 333.041992,460.856903 
M360.504822,475.939667 
	C343.752899,472.811981 329.919769,483.085419 328.454712,499.693420 
	C328.184784,502.753113 328.994171,503.926758 332.382446,503.228790 
	C346.841675,500.250122 361.345886,497.490143 375.832214,494.642700 
	C377.119934,494.389618 378.490875,494.252808 379.154755,492.865112 
	C377.275940,484.597321 370.885803,478.621796 360.504822,475.939667 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M516.026978,494.002319 
	C516.030823,503.325836 515.980896,512.150208 516.051636,520.973572 
	C516.152832,533.591797 524.273132,538.916626 536.012634,534.124023 
	C537.376892,533.567078 538.715698,532.947754 540.482300,532.175476 
	C543.416931,536.854797 544.718323,542.025574 546.675598,546.881104 
	C547.475708,548.865906 545.804016,550.004272 544.326050,550.749451 
	C532.105652,556.911011 519.592896,557.370850 507.367462,551.274231 
	C496.424133,545.817078 492.855042,535.620300 492.770935,524.198486 
	C492.568634,496.729034 492.781525,469.256714 492.630890,441.786591 
	C492.607147,437.461914 493.984497,435.700745 498.351227,435.954376 
	C502.499359,436.195282 506.676971,436.119537 510.833649,435.966705 
	C514.730835,435.823364 516.228821,437.571869 516.064880,441.368286 
	C515.899780,445.190125 516.177673,449.031311 516.006897,452.852631 
	C515.847290,456.423676 517.116943,458.036713 520.885864,457.884399 
	C526.038269,457.676117 531.208313,457.940521 536.366333,457.816986 
	C539.734436,457.736359 541.224426,459.063354 541.168213,462.512054 
	C540.877625,480.331940 543.528625,476.901581 527.534424,477.209656 
	C514.219788,477.466095 516.248047,475.539215 516.035767,488.507904 
	C516.008545,490.172455 516.028870,491.837769 516.026978,494.002319 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M404.830994,501.000000 
	C404.824585,488.020081 404.929749,475.538452 404.743347,463.061218 
	C404.682312,458.976807 406.055908,457.305573 410.218872,457.614868 
	C413.859467,457.885406 417.564362,457.954712 421.190155,457.597839 
	C426.115723,457.113129 427.855042,459.136902 427.311218,463.850555 
	C427.172119,465.056152 426.788422,466.500153 428.255035,467.907532 
	C435.503265,460.347046 444.685303,457.232574 454.911926,456.350189 
	C458.717407,456.021820 460.599060,456.784851 460.378784,460.990143 
	C460.152802,465.304718 460.218079,469.643799 460.361664,473.965363 
	C460.466248,477.114166 459.363953,478.440735 456.040222,478.343140 
	C434.464600,477.709717 428.008057,492.546600 428.187958,507.050781 
	C428.359192,520.859741 428.083435,534.674377 428.279907,548.482605 
	C428.341919,552.839355 426.826508,554.518616 422.492462,554.251038 
	C418.347961,553.995239 414.169006,554.036682 410.017883,554.225952 
	C406.116119,554.403992 404.737762,552.768311 404.768860,548.923401 
	C404.896790,533.116516 404.826477,517.307922 404.830994,501.000000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M488.031616,549.649475 
	C487.987122,553.049500 486.436951,554.232910 483.583557,554.192261 
	C478.592255,554.121094 473.599182,554.154480 468.606995,554.174805 
	C465.941528,554.185608 464.687805,552.988342 464.692169,550.240234 
	C464.729462,526.775452 464.735931,503.310608 464.695160,479.845856 
	C464.689850,476.787933 466.180420,475.737366 469.015381,475.780304 
	C473.341187,475.845825 477.685852,476.030701 481.991058,475.723755 
	C486.622314,475.393646 488.126099,477.327759 488.097351,481.786591 
	C487.965332,502.254974 488.037628,522.724731 488.037689,543.193970 
	C488.037689,545.190979 488.045258,547.187927 488.031616,549.649475 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M584.527710,474.429352 
	C599.642639,482.744263 614.437378,490.877136 630.467285,499.688934 
	C612.821960,511.763489 596.481140,522.945435 578.863342,535.001160 
	C578.863342,513.087769 578.863342,492.816772 578.863342,472.575806 
	C581.294128,471.956909 582.590881,473.567200 584.527710,474.429352 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M542.679016,514.788452 
	C542.679565,506.331085 542.680298,498.344391 542.684021,490.357727 
	C542.684998,488.340240 542.673523,486.341705 544.684143,485.050262 
	C551.400818,480.736115 558.091492,476.381317 564.821472,472.088013 
	C565.323547,471.767670 566.057495,471.810547 567.409119,471.538696 
	C567.409119,492.601074 567.409119,513.348267 567.409119,533.972839 
	C565.519165,535.076294 564.277710,533.998291 563.039673,533.258057 
	C557.901245,530.185730 552.890503,526.885254 547.644775,524.012024 
	C544.020569,522.026917 542.060242,519.470886 542.679016,514.788452 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M472.921021,442.967163 
	C474.019409,441.109222 474.493408,439.255432 477.043976,437.743469 
	C480.778198,444.369781 484.417999,450.945770 487.451019,458.775940 
	C479.760193,459.559906 472.835510,459.262451 465.070404,459.070831 
	C466.957611,452.801300 470.560120,448.368896 472.921021,442.967163 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M516.660767,572.048462 
	C518.355591,572.567139 519.609131,572.501831 520.953247,572.162109 
	C524.875854,571.170715 528.289429,572.264526 530.613403,575.535461 
	C532.908813,578.766235 532.918762,582.431641 531.066833,585.903564 
	C529.102417,589.586304 525.723755,590.584229 521.780640,590.186584 
	C520.835632,590.091248 519.885315,590.047791 519.177734,589.997498 
	C517.387451,591.856750 519.523804,596.060486 516.216248,595.732056 
	C512.515381,595.364624 514.108276,591.528381 513.976318,589.155334 
	C513.737549,584.860840 513.856445,580.544250 513.897156,576.237549 
	C513.914062,574.446106 513.710632,572.430603 516.660767,572.048462 
M527.230835,583.635437 
	C528.258606,580.440186 528.156128,577.538818 524.386292,576.396973 
	C521.757507,575.600830 519.932495,577.049561 518.873108,579.463867 
	C517.958374,581.548218 518.344666,583.510681 520.077332,584.855896 
	C522.360596,586.628601 524.707825,586.273743 527.230835,583.635437 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M427.695038,585.645874 
	C427.685028,580.219177 427.580902,575.246399 427.729858,570.281250 
	C427.781830,568.549988 426.617310,565.709595 429.719788,565.593872 
	C432.272217,565.498596 431.784576,568.067139 432.092834,569.765259 
	C432.208374,570.401855 432.357239,571.032410 432.468292,571.558472 
	C434.004211,572.825012 435.471771,571.725159 436.894775,571.774292 
	C442.184174,571.957336 445.920410,576.034119 445.846710,581.671021 
	C445.780914,586.698792 441.542023,590.457764 436.279449,590.255188 
	C434.321655,590.179810 432.521332,589.323303 430.416595,589.723022 
	C427.684235,590.242004 427.818665,587.923157 427.695038,585.645874 
M435.724396,576.264343 
	C432.134460,577.250122 431.640472,579.923340 432.427826,582.929565 
	C433.049072,585.301697 434.942810,586.424683 437.406586,586.054382 
	C439.836487,585.689148 441.349854,584.064697 441.504883,581.686523 
	C441.726135,578.292297 439.869476,576.455994 435.724396,576.264343 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M391.923279,580.957886 
	C389.842316,580.261841 388.059692,579.814331 386.373932,579.128723 
	C383.490356,577.955811 381.647766,575.876953 382.104187,572.597961 
	C382.535370,569.500549 384.477905,567.468567 387.552734,566.825684 
	C390.342865,566.242249 393.192383,566.169617 395.894989,567.306274 
	C397.135620,567.827881 398.401917,568.578552 397.774017,570.285034 
	C397.237457,571.743286 396.000854,571.551941 394.831207,571.233826 
	C391.976746,570.457214 387.996094,568.759155 387.122101,572.539307 
	C386.192596,576.559387 391.021606,575.928284 393.519318,576.913818 
	C396.844604,578.225952 399.707611,579.807983 399.191162,583.974487 
	C398.675446,588.135437 395.723602,589.793762 391.915375,590.195007 
	C389.052917,590.496582 386.260895,590.139404 383.739685,588.597656 
	C382.728333,587.979248 381.667694,587.265137 382.263885,585.846436 
	C382.860474,584.426697 384.030029,584.609863 385.215820,585.044800 
	C387.232605,585.784424 389.212524,586.808472 391.464813,586.169006 
	C392.734192,585.808533 394.178650,585.527405 394.483582,583.946167 
	C394.810150,582.252502 393.513550,581.655823 391.923279,580.957886 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M576.213257,588.351562 
	C569.541687,591.619507 563.871887,589.919189 561.840881,584.381104 
	C559.904541,579.100891 562.570618,573.650085 567.960510,572.155701 
	C572.089600,571.010864 575.801147,572.142212 577.927734,575.809814 
	C580.360046,580.004517 580.263306,584.378723 576.213257,588.351562 
M574.851196,582.170898 
	C575.169067,579.212036 574.433960,576.839600 571.105347,576.300293 
	C568.356934,575.854980 566.758911,577.491211 565.899719,579.860474 
	C565.018188,582.291077 566.237732,584.209656 568.208069,585.247559 
	C570.946594,586.690125 573.316284,585.954102 574.851196,582.170898 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M597.607178,580.682495 
	C597.106018,577.643494 595.758301,575.905518 592.875977,576.303406 
	C589.675720,576.745117 589.214905,579.192627 589.217468,581.869385 
	C589.219177,583.692627 589.200012,585.517944 589.111084,587.338440 
	C589.050842,588.572021 588.614197,589.686035 587.179565,589.807922 
	C585.749939,589.929443 584.944763,588.966248 584.916260,587.706482 
	C584.814880,583.235229 584.838196,578.760498 584.871338,574.287476 
	C584.877563,573.441772 585.314148,572.725281 586.135193,572.296265 
	C588.879333,570.862488 597.789246,572.093384 599.718201,574.188599 
	C601.656555,576.294006 602.823608,586.247864 601.425049,588.584961 
	C600.961670,589.359314 600.333374,589.875793 599.426880,589.735962 
	C598.527344,589.597168 597.913696,588.937927 597.861023,588.053101 
	C597.723206,585.738708 597.701538,583.417419 597.607178,580.682495 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M419.145386,589.876282 
	C416.751404,589.230225 414.830261,589.955444 412.865356,590.161011 
	C407.879486,590.682556 404.984619,588.190796 404.594238,583.161377 
	C404.401154,580.674316 404.196930,578.177490 404.225922,575.687744 
	C404.242554,574.258240 404.282379,572.353821 406.308502,572.277161 
	C408.528839,572.193176 408.238800,574.141541 408.307739,575.565918 
	C408.404297,577.560181 408.316193,579.562927 408.394531,581.558594 
	C408.491577,584.032227 409.611481,585.782837 412.266663,585.855530 
	C415.107239,585.933167 416.523712,584.266541 416.664154,581.520020 
	C416.783081,579.193665 416.750671,576.859619 416.862152,574.532654 
	C416.922791,573.267029 417.469208,572.268738 418.912750,572.164978 
	C419.859344,572.096985 420.761993,572.652954 420.774597,573.463867 
	C420.859528,578.942627 422.748749,584.576294 419.145386,589.876282 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M456.609070,586.276367 
	C457.858612,585.991638 458.990265,585.986450 459.067017,584.542053 
	C457.928650,582.802673 455.863556,583.058105 454.218323,582.546875 
	C451.559082,581.720520 449.704803,580.454163 449.760162,577.420349 
	C449.819366,574.176697 452.015594,572.782104 454.729614,572.033264 
	C455.816803,571.733276 457.091949,571.621826 458.168304,571.885925 
	C459.969543,572.328003 462.896240,572.151489 462.741302,574.673096 
	C462.547180,577.831360 459.762085,576.156921 457.989380,576.208557 
	C456.858032,576.241516 455.638580,575.943237 454.677185,577.408691 
	C455.502106,579.601196 457.766052,579.237061 459.481323,579.776367 
	C462.066315,580.589172 464.102478,581.970032 463.969757,584.947937 
	C463.840973,587.837952 461.817108,589.369812 459.211884,589.975403 
	C456.939148,590.503662 454.578613,590.519470 452.333649,589.719849 
	C450.945099,589.225342 449.200653,588.669556 449.664215,586.732605 
	C450.186249,584.551208 451.947021,585.439331 453.355316,585.702087 
	C454.333191,585.884583 455.306335,586.092285 456.609070,586.276367 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M614.872925,586.139893 
	C616.391235,584.932800 616.285706,584.006531 614.746460,583.464600 
	C613.345093,582.971130 611.872803,582.682373 610.442017,582.268005 
	C608.107483,581.591919 606.790466,580.070251 606.753479,577.592896 
	C606.717041,575.147156 607.972107,573.580627 610.174927,572.631165 
	C611.736816,571.957886 613.355347,571.561096 615.016113,571.841980 
	C616.902649,572.160950 619.728271,572.106689 619.720886,574.493164 
	C619.710693,577.796570 616.812988,575.983582 615.098694,576.150696 
	C613.974426,576.260376 612.758789,575.937866 611.679077,577.296753 
	C612.367554,579.555847 614.559387,579.315857 616.317383,579.807739 
	C618.858826,580.518738 621.134399,581.718323 621.028381,584.752197 
	C620.920227,587.845642 618.757568,589.484192 615.943054,590.039429 
	C613.498169,590.521667 610.985413,590.493103 608.625183,589.402954 
	C607.371338,588.823853 606.105957,588.135986 606.739136,586.462463 
	C607.283142,585.024658 608.581665,585.395813 609.733582,585.573181 
	C611.351440,585.822327 612.973206,586.046448 614.872925,586.139893 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M474.479156,590.104919 
	C468.655426,587.459229 466.660706,584.144043 467.554382,579.201599 
	C468.385254,574.606506 471.466827,572.481567 475.787140,571.812256 
	C477.471283,571.551392 479.113708,571.963379 480.584198,572.814209 
	C482.022430,573.646423 483.778076,574.588562 482.761139,576.612061 
	C481.805084,578.514587 480.311646,577.451843 478.929596,576.819580 
	C476.574615,575.742188 474.345459,575.892517 472.754303,578.191833 
	C471.524872,579.968506 471.335602,581.913452 472.594299,583.792053 
	C474.000031,585.890137 476.013031,586.266602 478.298248,585.570801 
	C479.926758,585.074890 482.106323,583.277466 482.867889,585.949768 
	C483.652344,588.702271 480.832123,589.380981 478.832275,589.987122 
	C477.609039,590.357788 476.205566,590.133789 474.479156,590.104919 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M541.406677,580.948364 
	C541.300354,583.729858 541.697205,585.707153 544.666260,585.941345 
	C545.977783,586.044800 546.930054,586.796387 546.745728,588.207947 
	C546.566040,589.584595 545.338989,590.003296 544.163635,590.140198 
	C540.677979,590.546143 538.034424,589.275146 537.780884,585.560364 
	C537.452942,580.754578 537.622620,575.913208 537.633850,571.087280 
	C537.636597,569.919495 537.767944,568.667847 539.305664,568.433838 
	C540.502136,568.251770 541.156860,568.907593 541.448242,570.008179 
	C541.738770,571.105652 542.509888,571.495972 543.573242,571.797546 
	C547.005371,572.770752 546.917847,574.486023 543.915588,575.763000 
	C541.491699,576.793945 541.281311,578.449829 541.406677,580.948364 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M493.071259,582.275879 
	C492.623566,585.127075 494.117828,589.416138 490.952271,589.411560 
	C486.967987,589.405884 489.253754,585.018250 488.695038,582.579529 
	C488.478271,581.633484 488.667786,580.594666 488.669128,579.598145 
	C488.678619,572.406250 488.679688,572.432312 495.547485,572.093445 
	C496.775513,572.032898 497.941650,571.843811 498.324432,573.371155 
	C498.654694,574.688843 498.139526,575.708923 496.845245,576.119751 
	C494.004822,577.021362 493.024017,579.077515 493.071259,582.275879 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M552.084595,574.546753 
	C552.513977,572.904907 553.137817,571.890259 554.572266,572.156189 
	C555.987183,572.418396 556.245911,573.651367 556.256470,574.854248 
	C556.291687,578.844482 556.302917,582.835571 556.259094,586.825623 
	C556.244507,588.153931 556.194824,589.692627 554.321411,589.705872 
	C552.589905,589.718079 552.106506,588.297791 552.071289,586.947388 
	C551.967224,582.960815 552.023438,578.970032 552.084595,574.546753 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M507.590271,575.787598 
	C507.531403,579.805359 507.508240,583.442566 507.490662,587.079834 
	C507.484802,588.291504 507.375366,589.595276 505.911896,589.760010 
	C504.129089,589.960632 503.727997,588.601501 503.706696,587.166687 
	C503.647766,583.196716 503.610718,579.226074 503.616241,575.255798 
	C503.618042,573.972168 503.376556,572.312195 505.170471,572.081055 
	C507.701508,571.755005 507.518005,573.763428 507.590271,575.787598 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M556.593506,567.926758 
	C555.370544,570.246765 553.937683,570.931091 552.313416,568.906738 
	C551.515198,567.911987 551.680542,566.606689 552.790039,565.858459 
	C554.862671,564.460693 556.156006,565.220398 556.593506,567.926758 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M505.844177,569.982422 
	C503.307251,569.476868 502.305756,568.259705 503.673065,566.161987 
	C504.359711,565.108521 505.622559,564.767639 506.671753,565.635071 
	C508.552032,567.189514 508.180847,568.675110 505.844177,569.982422 
z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
